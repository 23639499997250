import React from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image'

const Logo = () => {
    const data = useStaticQuery(graphql `
    query {
      sanitySiteSettings {
        logo {
          asset {
            gatsbyImageData(height: 80, placeholder: NONE)
          }
        }
      }
    }
  `);

    return (
            <GatsbyImage
                objectFit="contain"
                width="100"
                image={data.sanitySiteSettings.logo.asset.gatsbyImageData}
                alt="Logo"/>
    );
};

export default Logo;